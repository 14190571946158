import {Box} from '@mui/system'
import React from 'react'
import {Colors} from '../style'

function Container({children, style = {}}) {
  return (
    <Box sx={{...styles.container, ...style}}>
      {children}
    </Box>
  )
}

const styles = {
  container: {
    // paddingTop: '5%',
    width: '100%',
    height: '100%',
    backgroundColor: Colors.secondary,
    color: Colors.snowWhite,
    fontFamily: 'Roboto Mono',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  }
}

export default Container