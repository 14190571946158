import {Box} from '@mui/material'
import {display} from '@mui/system'
import React from 'react'
import Container from '../../components/Container'

import {BsBezier} from 'react-icons/bs'
import {ImCogs} from 'react-icons/im'
import {CgIfDesign} from 'react-icons/cg'
import {MdOutlineManageAccounts} from 'react-icons/md'
import {FiPaperclip} from 'react-icons/fi'

import {
  SiJavascript,
  SiReact,
  SiHtml5,
  SiCss3,
  SiExpress,
  SiWordpress,
  SiPython,
  SiCplusplus,
  SiCsharp
} from 'react-icons/si'
import {FaSass} from 'react-icons/fa'
import {GrMysql} from 'react-icons/gr'

import bookerDemo from '../../static/project_images/Booker/Booker-demo.png'
import bookerMainScreen from '../../static/project_images/Booker/main-screen.png'
import appoitmentsScreen from '../../static/project_images/Booker/appoitments-screen.png'
import multiLingual from '../../static/project_images/Booker/multilingual.png'
import Card from '../../components/Card'
import {Colors} from '../../style'

function Booker() {
  return (
    <Container>
      <Box sx={styles.hero}>
        <Box sx={styles.heroText}>
          <Box sx={styles.heroTitle}>
            <h1>Take your barbershop to the future with Booker</h1>
          </Box>
          <Box sx={styles.heroSubtitle}>
            <h4>Booker is a web application that allows barbershops to manage their appointments and customers.</h4>
          </Box>
        </Box>
        <Box sx={styles.heroImage}>
          <img loading='lazy' src={bookerDemo} alt="booker-demo" />
        </Box>
      </Box>
      <Box sx={styles.projectShowCase}>
        <Box sx={styles.showCaseRow}>
          <Box sx={styles.showCaseImage}>
            <img loading='lazy' src={bookerMainScreen} alt="booker-main-screen" />
          </Box>
          <Box sx={styles.showCaseText}>
            <h1>See all your stats and appoitments.</h1>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.projectShowCase}>
        <Box sx={styles.showCaseRow}>
          <Box sx={styles.showCaseText}>
            <h1>Manage all your appoitments with one click.</h1>
          </Box>
          <Box sx={styles.showCaseImage}>
            <img loading='lazy' src={appoitmentsScreen} alt="booker-main-screen" />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.projectShowCase}>
        <Box sx={styles.showCaseRow}>
          <Box sx={styles.showCaseImage}>
            <img loading='lazy' style={{width: '80%'}} src={multiLingual} alt="booker-main-screen" />
          </Box>
          <Box sx={styles.showCaseText}>
            <h1>multilingual support.</h1>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.projectShowCase}>
        <Box sx={styles.showCaseRow}>
          <Box sx={styles.showCaseText}>
            <h1>Recive bookings directly from your website and get notified on new bookings.</h1>
          </Box>
          <Box sx={styles.showCaseImage}>
            <img loading='lazy' style={{width: '100%'}} src={bookerDemo} alt="booker-main-screen" />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.row}>
        <h1>My role</h1>
        <ul>
          <li><h3><BsBezier /> Design</h3></li>
          <li><h3><FiPaperclip /> Copy writing</h3></li>
          <li><h3><ImCogs /> Back-end</h3></li>
          <li><h3><CgIfDesign /> Front-end</h3></li>
          <li><h3><MdOutlineManageAccounts /> Project management</h3></li>
        </ul>
      </Box>
      <Box sx={styles.row}>
        <h1>Tech stack</h1>
      <Box sx={styles.techStack}>
          <Card style={styles.stackCard}>
            <Box sx={styles.stackCardIcon}>
              <SiJavascript />
            </Box>
            <h1>JavaScript</h1>
          </Card>
          <Card style={styles.stackCard}>
            <Box sx={styles.stackCardIcon}>
              <SiReact />
            </Box>
              <h1>React JS/native</h1>
          </Card>
          <Card style={styles.stackCard}>
            <Box sx={styles.stackCardIcon}>
              <SiHtml5 />
            </Box>
              <h1>HTML</h1>
          </Card>
          <Card style={styles.stackCard}>
            <Box sx={styles.stackCardIcon}>
              <SiCss3 />
            </Box>
              <h1>CSS</h1>
          </Card>
          <Card style={styles.stackCard}>
            <Box sx={styles.stackCardIcon}>
              <SiExpress />
            </Box>
              <h1>Express JS</h1>
          </Card>
          <Card style={styles.stackCard}>
            <Box sx={styles.stackCardIcon}>
              <GrMysql />
            </Box>
              <h1>MySQL</h1>
          </Card>
        </Box>
        </Box>
    </Container>
  )
}

const styles = {
  hero: {
    paddingTop: '10%',
    backgroundColor: "#b999ff",
    backgroundImage: `radial-gradient(at 50% 20%, hsla(327,100%,70%,1) 0px, transparent 50%),
    radial-gradient(at 23% 44%, hsla(234,100%,70%,1) 0px, transparent 50%),
    radial-gradient(at 51% 0%, hsla(232,100%,70%,1) 0px, transparent 50%),
    radial-gradient(at 73% 4%, hsla(267,100%,70%,1) 0px, transparent 50%),
    radial-gradient(at 67% 34%, hsla(292,100%,70%,1) 0px, transparent 50%),
    radial-gradient(at 89% 42%, hsla(320,100%,70%,1) 0px, transparent 50%),
    radial-gradient(at 56% 56%, hsla(49,100%,70%,1) 0px, transparent 50%)`,
    height: '10%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    '@media screen and (max-width: 912px)': {
      flexDirection: 'column',
      paddingTop: '20%',
    }
  },
  heroText: {
    width: '50%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '0 5%',
    textShadow: '0 2px 5px #000',
    flexDirection: 'column',
    '@media screen and (max-width: 912px)': {
      width: '90%',
    }
  },
  heroTitle: {
    fontSize: '1.5rem',
  },
  heroSubtitle: {
    fontSize: '1rem',
  },
  heroImage: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media screen and (max-width: 912px)': {
      width: '90%',
      justifyContent: 'center',
      '& img': {
        width: '100%',
      }
    }

  },
  projectShowCase: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginTop: '5%',
    '@media screen and (max-width: 912px)': {
      marginTop: '10%',
    }
  },
  showCaseRow: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    '@media screen and (max-width: 912px)': {
      flexDirection: 'column',
    }
  },
  showCaseImage: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    'img': {
      width: '40%',
    },
    '@media screen and (max-width: 912px)': {
      width: '100%',
      '& img': {
        width: '100%',
      }
    }
  },
  showCaseText: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    'h1': {
      width: '50%',
    },
    '@media screen and (max-width: 912px)': {
      width: '100%',
      '& h1': {
        width: '100%',
      }
    }
  },
  row: {
    width: '90%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    'h1': {
      fontSize: '3rem',
    },
    'ul': {
      'li': {
        'h3': {
          fontSize: '2rem',
        }
      }
    }
  },
  techStack: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: '2rem',
    flexWrap: 'wrap',
  },
  stackCard: {
    width: '15rem',
    height: '9rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    marginBottom: '2rem',
    margin: '0 3rem',
    transition: 'all 0.3s ease',
    '*': {
      margin: '.3rem',
    },
    'h1': {
      fontSize: '1.2rem',
    },
    ':hover': {
      color: Colors.green,
    }
  },
  stackCardIcon: {
    fontSize: '3rem',
  }
}

export default Booker