export const Colors = {
	primary: "#2d2f33",
	secondary: "#25262A",
	tertiary: "#333438",
	hover: "#2D2F3A",
	green: "#5acaa4",
	darkGreen: "#409176",
	secondaryText: "#6f7580",
	snowWhite: "#f5f5f5"
};
