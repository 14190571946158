import {Box} from '@mui/system'
import React, {useState} from 'react'
import myLogo from '../static/abodsakka-logo.png'
import {Colors} from '../style'
import {FiGithub, FiInstagram, FiLinkedin, FiMail, FiX} from 'react-icons/fi'
import { RiMenu3Fill } from 'react-icons/ri'
import {Link} from '@mui/material'
function Navbar() {

  const [open, setOpen] = useState(false)

  const toggleMenu = () => {
    setOpen(!open)
  }

  return (
    <Box sx={styles.navbar}>
      <Box sx={styles.navbarRow}>
        <Link sx={styles.logoLink} href="/#">
          <img style={styles.logo} src={myLogo} alt="logo" />
        </Link>
      </Box>
      <Box sx={styles.navbarRow}>
        <Box sx={{
          ...styles.navbarLinks,
          right: open ? 0 : '-100%',
        }}>
          <a onClick={toggleMenu} href="/#" className={'navbarLink'}>Home</a>
          <a onClick={toggleMenu} href="/#about" className={'navbarLink'}>About</a>
          <a onClick={toggleMenu} href="/#projects" className={'navbarLink'}>Projects</a>
        </Box>
      </Box>
      <Box sx={{...styles.navbarRow, justifyContent: 'flex-end'}}>
        <Box sx={styles.navbarIcons}>
          <a href="https://github.com/abodsakah" target={'_blank'} className={'navbarIcon'}><FiGithub /></a>
          <a href="https://www.instagram.com/abodsakka/" target={'_blank'} className={'navbarIcon'}><FiInstagram /></a>
          <a href="https://www.linkedin.com/in/abdulrahman-sakah-34a181196/" target={'_blank'} className={'navbarIcon'}><FiLinkedin /></a>
          <a href="mailto:abodsakka2001@gmail.com" className={'navbarIcon'}><FiMail /></a>
        </Box>
        <Box onClick={toggleMenu} sx={styles.mobileNavBtn}>
          {open ? <FiX /> : <RiMenu3Fill />}
        </Box>
      </Box>

    </Box>
  )
}

const styles = {
  navbar: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 99,
    width: '100%',
    height: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    backdropFilter: 'blur(10px)',
  },
  navbarRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '33.3%',
    height: '100%',
  },
  logoLink: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    height: '70%',
    marginLeft: '2rem',
    marginRight: '25%',
  },
  navbarLinks: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    '@media (max-width: 912px)': {
      position: 'fixed',
      top: '100%',
      zIndex: 1000,
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      backgroundColor: Colors.primary,
    },
    transition: 'all 0.3s ease-in-out',
  },
  navbarIcons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '50%',
    marginRight: '2rem',
    '@media (max-width: 912px)': {
      display: 'none',
    },
    transition: 'all 0.3s ease-in-out',
  },
  mobileNavBtn: {
    color: '#fff',
    fontSize: '2.5rem',
    display: 'none',
    marginRight: '1.5rem',
    '@media (max-width: 912px)': {
      display: 'block',
    },
    transition: 'all 0.3s ease-in-out',
  }
}

export default Navbar