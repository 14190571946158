import logo from "./logo.svg";
import "./App.css";
import Navbar from "./components/Navbar";
import { Route, Routes } from "react-router-dom";
import Index from "./views/Index";
import Booker from "./views/Projects/Booker";
import Page404 from "./views/Page404";
import Yamen from "./views/Projects/Yamen";
import Foodie from "./views/Projects/Foodie";

function App() {
	return (
		<>
			<Navbar />
			<Routes>
				<Route path='/' element={<Index />} />
				<Route path='/project/booker' element={<Booker />} />
				<Route path='/project/yamenbarbershop' element={<Yamen />} />
				<Route path='/project/foodie' element={<Foodie />} />
				<Route path='*' element={<Page404 />} />
			</Routes>
		</>
	);
}

export default App;
