import {Box, Button, Typography} from '@mui/material'
import React from 'react'
import Card from '../components/Card'
import Container from '../components/Container'
import heroImage from '../static/hero-image.png'
import {Colors} from '../style'
import {BsFront, BsGearWideConnected, BsChevronDoubleRight} from 'react-icons/bs'
import {
  SiMaterialdesignicons,
} from 'react-icons/si'
import {BsBezier} from 'react-icons/bs'
import Experience from '../components/Experience'
import bookerLogo from '../static/project_images/Booker/Logo.png'
import yamenLogo from '../static/project_images/yamenbarbershop/Logo.png'
import foodieLogo from '../static/project_images/Foodie/Logo.png'
import myCv from '../static/Abdulrahman Sakah En.pdf'

function Index() {

  const goToProject = (projectURL) => {
    window.location.href = `/project/${projectURL}`
  }

  const openCV = () => { 
    window.open(myCv)
  }

  const goToGithub = () => {
    window.open('https://github.com/abodsakah');
  }

  return (
    <Container>
      <Box sx={styles.hero} id="#home">
        <Box sx={styles.heroText}>
          <Box sx={styles.heroBadge}>Fullstack Developer</Box>
          <Box sx={styles.heroTitle}>
            <h1>Talk is cheap.<br />Show me the code</h1>
          </Box>
          <Box sx={styles.heroSubtitle}>
            <h4>I design and code beautifully yet simply,<br />and I love what I do</h4>
          </Box>
          <Button onClick={openCV} style={{...styles.Button, alignSelf: 'flex-start'}} variant="contained">My Cv</Button>
        </Box>
        <Box sx={styles.heroImage}>
          <img src={heroImage} alt="hero" />
        </Box>
      </Box>
      
      <Box sx={styles.about} id="about">
        <Box sx={styles.aboutCards}>
          <Card style={styles.card}>
            <Box sx={styles.cardTitle}>
              <h1><BsFront /> Front-end</h1>
            </Box>
            <Box sx={styles.cardText}>
              <p> 
                I give the user the best experience they can imagine by creating a beautiful and responsive interface.
              </p>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={{...styles.cardTitle, color: Colors.green}}>
              <h1><BsGearWideConnected /> back-end</h1> 
            </Box>
            <Box sx={styles.cardText}>
              <p>  
                I develop elegant solutions to take care of anything that needs to be done behind the scenes.
              </p>
            </Box>
          </Card>
          <Card style={styles.card}>
            <Box sx={styles.cardTitle}>
              <h1><SiMaterialdesignicons /> Design</h1>
            </Box>
            <Box sx={styles.cardText}>
              <p>  
                I create digital products with a unique print.
              </p>
            </Box>
          </Card>
        </Box>
        <Box sx={styles.aboutText}>
          <p style={styles.secText}>Who am I?</p>
          <h1>Hello I am<br />Abdulrahman<br />Sakah</h1>
          <h2>Every greate product begins<br />with an amazing story</h2>
          <Typography sx={{...styles.description}}>Since the early years of my life, I was amazed by how things work and especially computers and computer programs so at the age of 10 I made it my goal to teach myself programming and specifically web development because the internet was the thing that amazed me the most.
After learning to make a simple website from the end I wanted to make more logic so I learned C# to develop games and from there, my journey started into programming. Today I develop websites and solutions all around the world.</Typography>
        </Box>
      </Box>

      <Box sx={styles.section}>
        <Box sx={styles.sectionTitle}>
          <h1>Experience</h1>
        </Box>
        <Experience />
      </Box>

      <Box sx={styles.section} id="projects">
        <Box sx={styles.sectionTitle}>
          <h1>Projects</h1>
        </Box>
        <Box sx={styles.projects}>
          <Card style={styles.project} onClick={() => goToProject("booker")}>
            <Box sx={styles.projectImage}>
              <img src={bookerLogo} alt="booker" />
            </Box>
            <Box sx={styles.projectText}>
              <h1>Booker</h1>
              <p>Booker is a plug-n-play system for barbershops and beauty salons which manages the customers and bookings of the salon.</p>
              <p style={styles.learnMoreLink}>Learn more <BsChevronDoubleRight /></p>

            </Box>
          </Card>
          <Card style={styles.project} onClick={() => goToProject("yamenbarbershop")}>
            <Box sx={styles.projectImage}>
              <img src={yamenLogo} alt="yamenBarbershop" />
            </Box>
            <Box sx={styles.projectText}>
              <h1>Yamen Barbershop</h1>
              <p>Yamen Barbersop is a barbershop located in Valdemarsvik Sweden, and they needed a website with bookings and a way to manage said bookings.</p>
              <p style={styles.learnMoreLink}>Learn more <BsChevronDoubleRight /></p>
            </Box>
          </Card>
          <Card style={styles.project} onClick={() => goToProject("foodie")}>
            <Box sx={styles.projectImage}>
              <img src={foodieLogo} alt="foodie" />
            </Box>
            <Box sx={styles.projectText}>
              <h1>Foodie</h1>
              <p>Foodie is a plug-n-play system for restaurant to be able to recive orders and manage said orders.</p>
              <p style={styles.learnMoreLink}>Learn more <BsChevronDoubleRight /></p>
            </Box>
          </Card>
        </Box>
          <Button style={{...styles.Button, alignSelf: 'center', marginBottom: '1rem'}} onClick={goToGithub} variant="contained">More projects on my github</Button>
      </Box>
    </Container>
  )
}

const styles = {
  hero: {
    paddingTop: '10%',
    width: '100%',
    height: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: Colors.primary,
    '@media (max-width: 912px)': {
      flexDirection: 'column',
      paddingTop: '25%'
    }
  },
  heroText: {
    width: '50%',
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '0 5%',
    flexDirection: 'column',
    '@media (max-width: 912px)': {
      width: '90%',

    }
  },
  heroBadge: {
    backgroundColor: Colors.green,
    padding: '0.3rem 1rem',
    borderRadius: '0.5rem',
    color: Colors.primary
  },
  heroTitle: {
    fontSize: '2rem',
    'h1': {
      fontWeight: 'normal',
      margin: '0',
    },
    '@media (max-width: 912px)': {
      fontSize: '1.5rem',
    }
  },
  heroSubtitle: {
    fontSize: '1.5rem',
    color: Colors.secondaryText,
    'h4': {
      fontWeight: 'light',
    }
  },
  Button: {
    backgroundColor: Colors.green,
    color: Colors.primary,
    fontSize: '1.2rem',
    padding: '0.5rem 1rem',
  },
  heroImage: {
    width: '50%',
    'img': {
      width: '80%',
    },
    '@media (max-width: 912px)': {
      width: '90%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }
  },
  about: {
    backgroundColor: Colors.secondary,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    '@media (max-width: 912px)': {
      flexDirection: 'column',
    }
  },
  aboutCards: {
    width: '50%',
    '@media (max-width: 912px)': {
      width: '90%',
    }
  },
  aboutText: {
    width: '50%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    color: Colors.snowWhite,
    'h1': {
      fontSize: '4rem',
      margin: '0',
      '@media (max-width: 912px)': {
        fontSize: '3rem',
      }
    },
    'h2': {
      fontSize: '2rem',
      margin: '0',
      '@media (max-width: 912px)': {
        fontSize: '1.5rem',
      }
    },
    '@media (max-width: 912px)': {
      width: '90%',
    }
  },
  description: {
    fontSize: '1.5rem',
    color: Colors.secondaryText,
    width: '65%',
    fontFamily: 'Roboto Mono',
    marginTop: '1rem',
    '@media (max-width: 912px)': {
      width: '100%',
    }
  },
  secText: {
    color: Colors.secondaryText,
    fontSize: '1.5rem',
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '90%',
    height: '10rem',
    '@media (max-width: 912px)': {
      marginTop: '2rem',
    }
  },
  cardTitle: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '1rem',
    fontSize: '1.5rem',
    '@media (max-width: 912px)': {
      width: '30%',
      fontSize: '1rem',
    }
  },
  cardText: {
    width: '55%',
    color: Colors.secondaryText,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.3rem',
    '@media (max-width: 912px)': {
      fontSize: '1rem',
    }
  },
  section: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  sectionTitle: {
    marginLeft: '1rem',
    fontSize: '2rem',
    '@media (max-width: 912px)': {
      fontSize: '1.5rem',
    }
  },
  projects: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'row',
    height: '100%',
    '@media (max-width: 912px)': {
      flexDirection: 'column',
    }
  },
  project: {
    width: '20%',
    alignSelf: 'stretch',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    'img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: '.5rem',
    },
    '&:hover': {
      transform: 'scale(1.05)',
    },
    '@media (max-width: 912px)': {
      width: '80%',
      alignSelf: 'center',

    }
  },
  learnMoreLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    transition: 'all 0.3s ease-in-out',
    color: Colors.green,
  }
}

export default Index