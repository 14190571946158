import React from 'react'
import Container from '../components/Container'
import {Colors} from '../style'

function Page404() {
  return (
    <Container style={styles.Container}>
      <h1>404</h1>
      <h2>Whoops something is wrong!</h2>
      <h4>Either the URL you are looking after is wrong or the page is missing.</h4>
      <a href="/">Go back to home</a>
    </Container>
  )
}

const styles = {
  Container: {
    backgroundColor: Colors.primary,
    height: '100%',
    textAlign: 'center',
    '& h1': {
      fontSize: '10rem',
      margin: '0',
      marginTop: '10%',
      marginBottom: '10%'
    },
    '& h2': {
      fontSize: '2rem',
      margin: '0',
    },
    '& h4': {
      fontSize: '1.5rem',
      fontWeight: 300,
      width: '90%',
    }
  }
}

export default Page404