import {Box} from '@mui/system'
import React from 'react'
import {Colors} from '../style'

function Card({children, style = {}, onClick = () => {}}) {
  return (
    <Box onClick={onClick} sx={{...styles.card, ...style}}>
      {children}
    </Box>
  )
}

const styles = {
  card: {
    backgroundColor: Colors.tertiary,
    margin: '2%',
    padding: '2%',
    borderRadius: '1rem',
    boxShadow: '-8px 9px 30px 15px rgba(0, 0, 0, 0.2)',
  }
}

export default Card