import {Box} from '@mui/material'
import React from 'react'
import {
  SiJavascript,
  SiReact,
  SiHtml5,
  SiCss3,
  SiExpress,
  SiWordpress,
  SiPython,
  SiCplusplus,
  SiCsharp
} from 'react-icons/si'
import {FaSass} from 'react-icons/fa'
import {GrMysql} from 'react-icons/gr'
import Card from './Card'
import {Colors} from '../style'
function Experience() {
  return (
    <Box sx={styles.experinceCards}>
          <Card style={styles.experinceCard}>
            <Box sx={styles.experinceCardIcon}>
              <SiJavascript />
            </Box>
            <Box sx={styles.experinceCardTitle}>
              <h1>JavaScript</h1>
            </Box>
            <Box sx={styles.experinceCardText}>
              <p>5+ years</p>
            </Box>
          </Card>
          <Card style={styles.experinceCard}>
            <Box sx={styles.experinceCardIcon}>
              <SiReact />
            </Box>
            <Box sx={styles.experinceCardTitle}>
              <h1>React JS/native</h1>
            </Box>
            <Box sx={styles.experinceCardText}>
              <p>3+ years</p>
            </Box>
          </Card>
          <Card style={styles.experinceCard}>
            <Box sx={styles.experinceCardIcon}>
              <SiHtml5 />
            </Box>
            <Box sx={styles.experinceCardTitle}>
              <h1>HTML</h1>
            </Box>
            <Box sx={styles.experinceCardText}>
              <p>7+ years</p>
            </Box>
          </Card>
          <Card style={styles.experinceCard}>
            <Box sx={styles.experinceCardIcon}>
              <SiCss3 />
            </Box>
            <Box sx={styles.experinceCardTitle}>
              <h1>CSS</h1>
            </Box>
            <Box sx={styles.experinceCardText}>
              <p>7+ years</p>
            </Box>
          </Card>
          <Card style={styles.experinceCard}>
            <Box sx={styles.experinceCardIcon}>
              <FaSass />
            </Box>
            <Box sx={styles.experinceCardTitle}>
              <h1>Sass</h1>
            </Box>
            <Box sx={styles.experinceCardText}>
              <p>3+ years</p>
            </Box>
          </Card>
          <Card style={styles.experinceCard}>
            <Box sx={styles.experinceCardIcon}>
              <SiExpress />
            </Box>
            <Box sx={styles.experinceCardTitle}>
              <h1>Express JS</h1>
            </Box>
            <Box sx={styles.experinceCardText}>
              <p>4+ years</p>
            </Box>
          </Card>
          <Card style={styles.experinceCard}>
            <Box sx={styles.experinceCardIcon}>
              <SiWordpress />
            </Box>
            <Box sx={styles.experinceCardTitle}>
              <h1>Wordpress</h1>
            </Box>
            <Box sx={styles.experinceCardText}>
              <p>6+ years</p>
            </Box>
          </Card>
          <Card style={styles.experinceCard}>
            <Box sx={styles.experinceCardIcon}>
              <GrMysql />
            </Box>
            <Box sx={styles.experinceCardTitle}>
              <h1>MySQL</h1>
            </Box>
            <Box sx={styles.experinceCardText}>
              <p>5+ years</p>
              </Box>
          </Card>
          <Card style={styles.experinceCard}>
            <Box sx={styles.experinceCardIcon}>
              <SiPython />
            </Box>
            <Box sx={styles.experinceCardTitle}>
              <h1>Python</h1>
            </Box>
            <Box sx={styles.experinceCardText}>
              <p>6+ years</p>
              </Box>
          </Card>
          <Card style={styles.experinceCard}>
            <Box sx={styles.experinceCardIcon}>
              <SiCsharp />
            </Box>
            <Box sx={styles.experinceCardTitle}>
              <h1>C#</h1>
            </Box>
            <Box sx={styles.experinceCardText}>
              <p>5+ years</p>
              </Box>
          </Card>
          <Card style={styles.experinceCard}>
            <Box sx={styles.experinceCardIcon}>
              <SiCplusplus />
            </Box>
            <Box sx={styles.experinceCardTitle}>
              <h1>C++</h1>
            </Box>
            <Box sx={styles.experinceCardText}>
              <p>2+ years</p>
              </Box>
          </Card>
        </Box>
  )
}

const styles = {
  experinceCards: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: '2rem',
    flexWrap: 'wrap',
  },
  experinceCard: {
    width: '15rem',
    height: '9rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    marginBottom: '2rem',
    margin: '0 3rem',
    transition: 'all 0.3s ease',
    '*': {
      margin: '.3rem',
    },
    'h1': {
      fontSize: '1.2rem',
    },
    ':hover': {
      color: Colors.green,
    }
  },
  experinceCardIcon: {
    fontSize: '3rem',
  }
}

export default Experience